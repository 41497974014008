import {Link} from '@prescriberpoint/ui';
import clsx from 'clsx';
import {FC, useCallback, useRef} from 'react';
import AnimateHeight from 'react-animate-height';
import Markdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import {CEVENT_VIEW_MORE_BTN_CLICKED, DlvViewMoreBtnClicked} from '@/constants';
import {OTHER_SECTION_IDS} from '@/constants/sectionIDs';
import {DRUG_SUMMARIES} from '@/data/contentSummaries';
import {useShowContentSummaries} from '@/hooks';
import useViewMore from '@/hooks/useViewMore';
import {customEvent, proccessSectionId} from '@/utils/gtm';

const DEFAULT_HEIGHT = 150;

interface IContentProps {
  setId: string;
  sanitizedText: string;
}

export const Content: FC<IContentProps> = ({setId, sanitizedText}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const {height, toggleHeight, showClampButton, textClamped} = useViewMore(
    containerRef,
    DEFAULT_HEIGHT,
  );

  const showContentSummaries = useShowContentSummaries(setId);

  const adjustHeight = useCallback(
    (e: any) => {
      e.preventDefault();
      if (textClamped) {
        customEvent<DlvViewMoreBtnClicked>(CEVENT_VIEW_MORE_BTN_CLICKED, {
          resourceSection: proccessSectionId(
            OTHER_SECTION_IDS.dosingAndAdmiministration,
          ),
        });
      }
      toggleHeight();
    },
    [toggleHeight, textClamped],
  );

  if (showContentSummaries) {
    return (
      <div
        ref={containerRef}
        className='drug-label-table overflow-x-auto text-sm text-neutral-primary [&>p>a]:!text-neutral-primary [&>table]:mb-4 [&>table]:w-full'>
        <Markdown remarkPlugins={[remarkGfm]}>
          {DRUG_SUMMARIES?.[setId]}
        </Markdown>
      </div>
    );
  }

  return (
    <div className='relative flex flex-col'>
      <AnimateHeight height={height} duration={500}>
        <div
          ref={containerRef}
          dangerouslySetInnerHTML={{
            __html: sanitizedText,
          }}
          className={clsx('drug-label-table overflow-x-auto text-sm', [
            'text-neutral-primary [&>div>:first-child]:mt-0',
            '[&>div>label>label>label]:inline-block [&>div>label>label>label]:pl-5',
          ])}
        />
      </AnimateHeight>
      <div className='flex w-full items-end justify-end transition-all'>
        {showClampButton ? (
          <Link
            type='underline'
            aria-expanded={!textClamped}
            onClick={adjustHeight}
            linkSize='lg'
            label={textClamped ? 'View more' : 'View less'}
          />
        ) : null}
      </div>
    </div>
  );
};
