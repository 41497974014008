import clsx from 'clsx';
import dynamic from 'next/dynamic';
import {FC, memo} from 'react';
import Sections from './Sections';
import Header from './v2/Header';
import PdpHeaderV1 from '@/components/PdpHeaderV1';
import SignUpForUpdates from '@/components/SignUpForUpdates';
import * as FLAGS from '@/constants/flags';
import {FULL_SECTION_IDS, SECTION_IDS} from '@/constants/sectionIDs';
import {useFlag} from '@/context';
import {
  useAutoOpenChatBox,
  useCurrentDrug,
  useCurrentSlug,
  useEnhanced,
  useIsCoverageToolAvailable,
  useRenderInIFrame,
  useShowEnhancedSection,
  useSectionsStatus,
} from '@/hooks';
import {PageContentLayout} from '@/layouts';
import {useInteractionsByDrug} from '@/queries';

const CTAMenu = dynamic(() => import('./CTAMenu'));

export interface ProductDetailProps {}

const ProductDetail: FC<ProductDetailProps> = () => {
  const {setId} = useCurrentSlug();
  const {isEnhanced, samplesAvailable, newHeaderAvailable} = useEnhanced(setId);
  const {drugName, genericName} = useCurrentDrug();
  const pdpIsEmbedded = useRenderInIFrame();

  const showPA = useShowEnhancedSection(
    SECTION_IDS.priorAuthorization,
    FLAGS.PRIOR_AUTHORIZATION_SECTION,
  );
  const showPE = useFlag(FLAGS.PATIENT_EDUCATION_SECTION) && isEnhanced;

  const showSignUpDrugUpdates =
    useFlag(FLAGS.SIGNUP_DRUG_UPDATE) &&
    drugName === 'Cibinqo' &&
    !pdpIsEmbedded; // this is only being rendered for Cibinqo;

  const showHeaderV2 = useFlag(FLAGS.SHOW_V2_PPT_DESIGN);
  const showCTA = !pdpIsEmbedded && !showHeaderV2;
  const {showCoverageOnPdp} = useIsCoverageToolAvailable();

  const {data: interactionsData} = useInteractionsByDrug(setId, true);
  const showDDI = !!(useFlag(FLAGS.DDI_TOOL) && interactionsData?.totalItems);

  const sectionsStatus = useSectionsStatus();
  const showFA = !!sectionsStatus[FULL_SECTION_IDS.financialAssistance];

  useAutoOpenChatBox();
  const showNewHeader = useFlag(FLAGS.NEW_HEADER) && newHeaderAvailable;

  return (
    <div
      className={clsx(
        'w-full',
        showNewHeader ? 'bg-neutral-white' : 'bg-neutral-lighter-alt',
      )}>
      {showNewHeader ? (
        <Header
          samples={samplesAvailable}
          coverage={showCoverageOnPdp}
          interactions={showDDI}
          financialAssistance={showFA}
          priorAuthorization={showPA}
          patientEducation={showPE}
          className='md:pb-12'
        />
      ) : null}
      <PageContentLayout>
        <div
          className={clsx(
            'min-h-page-content bg-neutral-lighter-alt px-4',
            [showNewHeader ? 'bg-neutral-white md:px-10' : 'md:px-0'],
            {'px-6 pt-4': pdpIsEmbedded},
          )}>
          {!showNewHeader ? (
            <PdpHeaderV1 drugName={drugName} genericName={genericName} />
          ) : null}
          {showSignUpDrugUpdates ? <SignUpForUpdates /> : null}
          <div className='divide-x-0 divide-y divide-solid divide-gray-200'>
            <Sections />
          </div>
        </div>
        {showCTA ? <CTAMenu /> : null}
      </PageContentLayout>
    </div>
  );
};

export {default as OTCProductDetail} from './OTCProductDetail';
export default memo(ProductDetail);
