import {Button, Text} from '@prescriberpoint/ui';
import clsx, {ClassValue} from 'clsx';
import {FC, useCallback} from 'react';
import {HIGHLIGHT_SECTION_TEXTS} from './data';
import {HighlightSectionType} from './models';
import {useUserAgentContext} from '@/context';
import {useCurrentDrug} from '@/hooks';
import useUrlsData, {PageType} from '@/hooks/useUrlsData';
import ResponsiveCoverageWrapper from '@/modules/Coverage/ResponsiveCoverageWrapper';
import {useInteractionsByDrug} from '@/queries';
import {replaceVariablesInStr} from '@/utils';
export interface IHighlightSectionProps {
  section: HighlightSectionType;
  className?: ClassValue;
}

const HighlightSection: FC<IHighlightSectionProps> = ({section, className}) => {
  const {slug, setId} = useCurrentDrug();
  const {isMobileOrTablet} = useUserAgentContext();
  const {getUrlByPage} = useUrlsData(slug as string);
  const {data: interactionsData} = useInteractionsByDrug(setId, true);
  const title = HIGHLIGHT_SECTION_TEXTS[section].title;

  const description = HIGHLIGHT_SECTION_TEXTS[section].description;
  const cta = HIGHLIGHT_SECTION_TEXTS[section].cta;

  const getInteractionsTitle = useCallback(() => {
    const quantity = interactionsData?.totalItems ?? 0;
    return quantity >= 50 ? '50+ ' : quantity.toString();
  }, [interactionsData?.totalItems]);

  const titleWithoutVars =
    section === 'interactions'
      ? replaceVariablesInStr(title, {
          quantity: getInteractionsTitle(),
        })
      : title;

  const handleOnClick = useCallback(
    (section: HighlightSectionType) => {
      const ctaUrl = getUrlByPage(section as PageType);
      if (ctaUrl) {
        window.location.href = ctaUrl;
      }
    },
    [getUrlByPage],
  );

  if (section === 'coverage') {
    return <ResponsiveCoverageWrapper highlighted />;
  }

  return (
    <div
      className={clsx(
        'flex w-full flex-col items-center justify-center space-y-5',
        className,
      )}>
      <div className='flex w-full flex-col justify-center space-y-2'>
        <Text
          as={isMobileOrTablet ? 'headline-sm' : 'headline-lg'}
          className='text-center'
          weight='bold'>
          {titleWithoutVars}
        </Text>
        {description ? (
          <Text
            as={isMobileOrTablet ? 'body-md' : 'title-md'}
            className='text-center'>
            {description}
          </Text>
        ) : null}
      </div>
      {cta ? (
        <Button
          id='highlight_section_cta'
          stretched={isMobileOrTablet}
          className='md:min-w-[200px]'
          onClick={() => handleOnClick(section)}
          size='lg'>
          {cta}
        </Button>
      ) : null}
    </div>
  );
};

export default HighlightSection;
