import dynamic from 'next/dynamic';
import Hero from './Hero';
import * as FLAGS from '@/constants/flags';
import {SECTION_IDS} from '@/constants/sectionIDs';
import {useFlag, usePharmacyContext} from '@/context';
import {
  useAutoOpenChatBox,
  useCurrentSlug,
  useEnhanced,
  useIsCoverageToolAvailable,
  useRenderInIFrame,
  useShowEnhancedSection,
} from '@/hooks';
import {useIsOncology} from '@/hooks/useIsOncology';

const LabelSection = dynamic(() => import('../LabelSection'));

const FASections = dynamic(
  () => import('@/modules/ProductDetailPage/FASections'),
);
const PatientEducation = dynamic(
  () => import('@/modules/ProductDetailPage/PatientEducation'),
);
const PeopleAlsoAsk = dynamic(() => import('../PeopleAlsoAsk'));
const PriorAuthorizationSection = dynamic(
  () => import('@/modules/ProductDetailPage/PriorAuthorizationSection'),
);
const RequestSample = dynamic(
  () => import('@/modules/ProductDetailPage/RequestSamples/RequestSample'),
);
const Pharmacy = dynamic(() => import('@/modules/ProductDetailPage/Pharmacy'));
const Biomarker = dynamic(
  () => import('@/modules/ProductDetailPage/Biomarker'),
);
const PubMedNews = dynamic(
  () => import('@/modules/ProductDetailPage/PubMedNews'),
);
const CoverageRestrictions = dynamic(
  () => import('@/modules/ProductDetailPage/CoverageRestrictions'),
);
const Ai = dynamic(() => import('./AI'), {ssr: false});

const Sections = () => {
  const {setId} = useCurrentSlug();
  const {isEnhanced} = useEnhanced(setId);
  const {isOncologyDrug} = useIsOncology();
  const pdpIsEmbedded = useRenderInIFrame();

  const showPA = useShowEnhancedSection(
    SECTION_IDS.priorAuthorization,
    FLAGS.PRIOR_AUTHORIZATION_SECTION,
  );
  const showPubMed = useFlag(FLAGS.PUB_MED_ARTICLES); // TODO I use only the flag because we need to verify is this is only for enhanced drugs and if we have reosurces
  const showPE = useFlag(FLAGS.PATIENT_EDUCATION_SECTION) && isEnhanced;
  const showRS =
    useShowEnhancedSection(
      SECTION_IDS.requestSamples,
      FLAGS.REQUEST_SAMPLES_SECTION,
    ) && !pdpIsEmbedded;
  const {showPH} = usePharmacyContext();
  const showEF =
    useFlag(FLAGS.BIOMARKER_SECTION) && isOncologyDrug && !pdpIsEmbedded;

  const showFAQS =
    useShowEnhancedSection(
      SECTION_IDS.peopleAlsoAsk,
      FLAGS.PEOPLE_ALSO_ASK_SECTION,
      false,
    ) && !pdpIsEmbedded;

  const showLS = !pdpIsEmbedded;

  const {showCoverageOnPdp, isCoverageHighlited} = useIsCoverageToolAvailable();

  useAutoOpenChatBox();

  return (
    <>
      <Hero />
      {showCoverageOnPdp && !isCoverageHighlited ? <CoverageRestrictions /> : null}
      <Ai />
      {showEF ? <Biomarker /> : null}
      {showLS ? <LabelSection /> : null}
      {showRS ? <RequestSample /> : null}
      {showPA ? <PriorAuthorizationSection /> : null}
      {showPH && !pdpIsEmbedded ? <Pharmacy /> : null}
      <FASections setId={setId} />
      {showPubMed ? <PubMedNews /> : null}
      {showPE ? <PatientEducation /> : null}
      {showFAQS ? <PeopleAlsoAsk /> : null}
    </>
  );
};

export default Sections;
