import {ActionListItemV2, toSnakeCase} from '@prescriberpoint/ui';
import {useMemo, useState, useRef, useCallback} from 'react';
import {useCookies} from 'react-cookie';
import {
  getLinkParentSectionFromResource,
  getLinkSectionFromResource,
} from '../ResourceLink';
import Share from '../Share';
import {SPLIT_USER_COOKIE} from '@/constants/cookies';
import {AFFORDABILITY_ON_PDP} from '@/constants/flags';
import {
  CEVENT_BRAND_RSC_CLICK,
  CEVENT_BRAND_RSC_CONTINUE_TO_WEBSITE,
  DlvBrandRscContinueToWebsite,
  DlvRscClick,
} from '@/constants/gtm';
import {useFlag} from '@/context';
import {useCurrentDrug, useFirePaywall} from '@/hooks';
import {EnhancedContentLinkDto, RecentDocumentDto} from '@/models';
import {formatISOtoString} from '@/utils/date';
import {
  appendToArrayVariable,
  customEvent,
  proccessSectionId,
} from '@/utils/gtm';
import {
  getResourceImg,
  isEnhancedContentLink,
  isMostRecentPaResource,
} from '@/utils/resources';

interface ActionListItemWithShareProps {
  resource: EnhancedContentLinkDto | RecentDocumentDto;
  isMostRecent?: boolean;
  type: 'download' | 'open';
}

const ActionListItemWithShare = ({
  resource,
  isMostRecent,
  type,
}: ActionListItemWithShareProps) => {
  const [isShareOpen, setIsShareOpen] = useState(false);
  const [shareSection, setShareSection] = useState<'visit' | 'share'>('visit');
  const [renderUnderButton, setRenderUnderButton] = useState(false);

  const {drugName} = useCurrentDrug();
  const firePaywall = useFirePaywall();
  const affordabilityOnPdp = useFlag(AFFORDABILITY_ON_PDP);
  const [cookies] = useCookies(['hubspotutk', SPLIT_USER_COOKIE]);
  const buttonRef = useRef<HTMLDivElement>(null);

  const linkParentSection = useMemo(() => {
    if (isMostRecent) {
      return 'parentSectionPriorAuthorization';
    }
    if (isEnhancedContentLink(resource)) {
      return getLinkParentSectionFromResource(resource);
    }
    return '';
  }, [resource, isMostRecent]);

  const linkSection = useMemo(() => {
    if (isMostRecent) {
      return 'sectionRecentPriorAuthorizationForms';
    }
    if (isEnhancedContentLink(resource)) {
      return getLinkSectionFromResource(resource);
    }
    return '';
  }, [resource, isMostRecent]);

  const fireCustomEvent = useCallback(() => {
    customEvent<DlvRscClick>(CEVENT_BRAND_RSC_CLICK, {
      drugName,
      resourceName: resource.title,
      resourceParentSection: proccessSectionId(linkParentSection),
      resourceSection: proccessSectionId(linkSection),
      resourceImage: getResourceImg(resource.title, drugName),
      redirectUrl: resource.href,
      firePaywall: firePaywall,
      userKey: cookies[SPLIT_USER_COOKIE],
      experimentName: AFFORDABILITY_ON_PDP,
      treatment: affordabilityOnPdp,
    });
  }, [
    affordabilityOnPdp,
    cookies,
    drugName,
    firePaywall,
    linkParentSection,
    linkSection,
    resource.href,
    resource.title,
  ]);

  const defineSharePosition = () => {
    const {bottom} = buttonRef?.current?.getBoundingClientRect() ?? {bottom: 0};
    const shareHeight = 320;
    const distanceToViewportBottom = window.innerHeight - bottom;

    if (distanceToViewportBottom > shareHeight) {
      setRenderUnderButton(true);
    } else {
      setRenderUnderButton(false);
    }
  };

  const handleOpenShare = useCallback(() => {
    appendToArrayVariable('links', resource.href);
    if (resource?.title && resource?.href) {
      defineSharePosition();
      setIsShareOpen(true);
    }
  }, [resource]);

  const handleShareClick = useCallback(() => {
    fireCustomEvent();
    if (!firePaywall) {
      setShareSection('share');
      handleOpenShare();
    }
  }, [firePaywall, fireCustomEvent, handleOpenShare]);

  // When clicking on the resource name or first action item we don't show the interstitial modal
  const handleActionClick = useCallback(() => {
    fireCustomEvent();
    customEvent<DlvBrandRscContinueToWebsite>(
      CEVENT_BRAND_RSC_CONTINUE_TO_WEBSITE,
      {
        drugName: drugName,
        resourceName: resource.title,
        link: resource.href,
        userKey: cookies[SPLIT_USER_COOKIE],
      },
    );
    if (!firePaywall) {
      window.open(resource.href, '_blank');
    }
  }, [firePaywall, fireCustomEvent, resource, cookies, drugName]);

  return (
    <div ref={buttonRef} className='relative list-none'>
      <ActionListItemV2
        title={resource.title}
        tagFirstText={
          isMostRecentPaResource(resource)
            ? `Verified: ${formatISOtoString(resource.updatedDate)}`
            : ''
        }
        firstAction={type}
        onOpen={type === 'open' ? handleActionClick : undefined}
        onDownload={type === 'download' ? handleActionClick : undefined}
        onShare={handleShareClick}
      />
      <Share
        id={`${toSnakeCase(resource?.title)}_share`}
        drugName={drugName}
        isShareOpen={isShareOpen}
        handleClose={() => setIsShareOpen(false)}
        renderUnderButton={renderUnderButton}
        shareLink={resource?.href ?? ''}
        resource={resource?.title ?? ''}
        resourceParentSection={linkParentSection}
        resourceSection={linkSection}
        section={shareSection}
      />
    </div>
  );
};

ActionListItemWithShare.displayName = 'ActionListItemWithShare';

export default ActionListItemWithShare;
