import DOMPurify from 'isomorphic-dompurify';
import {FC, useMemo} from 'react';
import {Content} from './Content';
import {SectionNew} from '@/components';
import {OTHER_SECTION_IDS} from '@/constants/sectionIDs';
import {useCurrentSlug, useShowContentSummaries} from '@/hooks';
import {useLabel} from '@/queries';
import {
  removeAllHTMLElementInStr,
  removeHeaderNumbersAndAnchors,
  formatDosageHTML,
  removeTableWidthAttribute,
} from '@/utils/string';

const DosingAndAdmin: FC = () => {
  const {setId} = useCurrentSlug();
  const {data: product} = useLabel(setId);
  const content = product?.label.highlights.dosageAndAdministration ?? '';
  const showContentSummaries = useShowContentSummaries(setId);

  const sanitizedText = useMemo(() => {
    let safeContent = removeHeaderNumbersAndAnchors(
      removeAllHTMLElementInStr(content, 'h1'),
    ).replace(/\s{9}/g, '');

    safeContent = removeTableWidthAttribute(safeContent);

    return DOMPurify.sanitize(formatDosageHTML(safeContent));
  }, [content]);

  return (
    <SectionNew
      id={OTHER_SECTION_IDS.dosingAndAdmiministration}
      data-testid={OTHER_SECTION_IDS.dosingAndAdmiministration}
      title={
        showContentSummaries ? 'Quick Reference' : 'Dosage & Administration'
      }>
      <Content setId={setId} sanitizedText={sanitizedText} />
    </SectionNew>
  );
};

const DosingAndAdminWrapper: FC = () => {
  const {setId} = useCurrentSlug();
  const {data: product} = useLabel(setId);
  const content = product?.label?.highlights?.dosageAndAdministration;
  return content ? <DosingAndAdmin /> : null;
};

export default DosingAndAdminWrapper;
